import axios from 'axios'
import { message } from 'ant-design-vue'

const instance = axios.create({
  timeout: 10000 // 请求超时时间 10s
})
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

instance.interceptors.response.use(response => {
  return response.data
}, error => {
  console.error(error.response)
  if (error.response) {
    const status = error.response.status
    message.error(`${status}: 异常网络响应`)
  }
  return Promise.reject(error.response)
})

export default instance
