<template>
  <div class="box">
    <div class="box-title">
      <h1>账号登录</h1>
    </div>
    <a-form :form="form" @click="handleSubmit">
      <a-form-item>
        <a-input placeholder="用户名/手机号" v-decorator="formRules.username" size="large">
          <a-icon slot="prefix" type="user" class="i-icon"/>
          <a-icon slot="suffix" type="down" class="i-icon" @click="handleAccountList" v-if="accountList.length > 0"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password type="password" placeholder="密码" v-decorator="formRules.password" size="large">
          <a-icon slot="prefix" type="lock" class="i-icon"/>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" block size="large" @click="handleSubmit" :loading="loginLoading">登  录</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="link" @click="$router.push({ path: '/sms' })">账号注册</a-button>
        <a-button type="link" @click="handleFastRegister" :loading="fastRegisterLoading">极速试玩</a-button>
        <a-button type="link" @click="$router.push({ path: '/forget' })">忘记密码</a-button>
        <a-button type="primary" block ghost size="large" @click="handleCancelAutoLogin" v-show="autoLoginShow">取消自动登录( {{ timeSeconds }}s )</a-button>
      </a-form-item>
    </a-form>
    <a-modal v-model="accountListVisible" title="本地缓存账号">
      <template slot="footer">
        <a-button :type="accountToDelete? 'danger' : 'dashed'" @click="handleModalEdit">编 辑</a-button>
        <a-button type="primary" @click="handleModalOk" ghost>完 成</a-button>
      </template>
      <a-list item-layout="horizontal" :data-source="accountList">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-icon slot="actions" type="check-circle" theme="twoTone" two-tone-color="#52c41a" v-if="item.username===currentUsername"/>
          <a-icon slot="actions" type="delete" theme="twoTone" two-tone-color="#ff4d4f" @click="handleAccountDelete(item)" v-if="accountToDelete"/>
          <a-list-item-meta>
            <div slot="title" @click="handleAccountChange(item)">{{ item.username }}</div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-modal>
    <xs-mobile-bind :visible="mobileBindDialogVisible" @next="handleBindMobileNext"></xs-mobile-bind>
    <xs-id-card-bind :visible="idCardBindDialogVisible" @next="handleBindIdCardNext"></xs-id-card-bind>
  </div>
</template>

<script>
import api from '@/api'
import XsMobileBind from '@/components/XsMobileBind'
import XsIdCardBind from '@/components/XsIdCardBind'

export default {
  components: {
    XsMobileBind,
    XsIdCardBind
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'normal_login' }),
      formRules: {
        username: [
          'username',
          {
            initialValue: this.$store.getters.currentUsername,
            rules: [{
              required: true,
              message: '请输入用户名！'
            }]
          }
        ],
        password: [
          'password',
          {
            initialValue: this.$store.getters.currentPassword,
            rules: [{
              required: true,
              message: '请输入密码！'
            }]
          }
        ]
      },
      accountListVisible: false,
      accountToDelete: false,
      loginLoading: false,
      fastRegisterLoading: false,
      autoLoginShow: false,
      timeSeconds: 0,
      timer: null,
      mobileBindDialogVisible: false,
      idCardBindDialogVisible: false,
      account: {}
    }
  },
  created () {
    if (!this.isArgsError) {
      this.checkAutoLogin()
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    channelId () {
      return this.$store.getters.channelId
    },
    currentUsername () {
      return this.$store.getters.currentUsername
    },
    accountList () {
      return this.$store.getters.accountList
    },
    currentPassword () {
      return this.$store.getters.currentPassword
    },
    isArgsError () {
      return this.$store.getters.isArgsError
    },
    os () {
      return this.$store.getters.os
    },
    deviceId () {
      return this.$store.getters.deviceId
    }
  },
  methods: {
    handleSubmit () {
      this.form.validateFields((err, values) => {
        this.autoLoginShow = false
        if (err) {
          return
        }
        const params = {
          appId: this.appId,
          type: 1,
          channelId: this.channelId,
          username: values.username,
          password: values.password,
          deviceId: this.deviceId,
          os: this.os
        }
        this.loginLoading = true
        api.h5Login(params).then(res => {
          this.loginLoading = false
          // const code = res.code || 0
          const { code, msg, data } = res
          if (code !== 1) {
            const message = msg || '用户名密码错误'
            this.$message.error(message)
          } else {
            data.password = params.password
            this.$store.dispatch('SaveAccountInfo', data).then(() => {
              this.$message.success('登录成功')
              this.account = data
              // 0. 普通用户（已实名成年用户）, 1. 未实名用户, 2. 8周岁以下未成年人, 3. 8-16周岁未成年人, 4. 16-18周岁未成年人, 5. 游客
              const userType = data.userType || 0
              if (userType === 1) {
                this.checkShowBindIdCardDialog()
              } else {
                window.location.href = data.gameUrl
              }
            })
          }
        }).catch(() => {
          this.loginLoading = false
        })
      })
    },
    handleAccountList () {
      this.accountListVisible = true
    },
    handleAccountChange (item) {
      this.$store.dispatch('ChangeUsername', item.username).then(() => {
        this.form.setFieldsValue({
          username: item.username,
          password: item.password
        })
        this.accountListVisible = false
        this.accountToDelete = false
      })
    },
    handleModalOk () {
      this.accountListVisible = false
      this.accountToDelete = false
    },
    handleModalEdit () {
      this.accountToDelete = !this.accountToDelete
    },
    handleAccountDelete (item) {
      this.$store.dispatch('RemoveAccountInfo', item.username).then(() => {
        this.form.setFieldsValue({
          username: this.currentUsername,
          password: this.currentPassword
        })
      })
    },
    handleFastRegister () {
      const params = {
        appId: this.appId,
        channelId: this.channelId
      }
      this.fastRegisterLoading = true
      api.h5RegisterFast(params).then(res => {
        this.fastRegisterLoading = false
        const { code, msg, data } = res
        if (code !== 1) {
          const message = msg || '未知错误'
          this.$message.error(message)
        } else {
          this.$store.dispatch('SaveAccountInfo', data).then(() => {
            this.form.setFieldsValue({
              username: this.currentUsername,
              password: this.currentPassword
            })
            this.$message.success('快速注册账号成功')
            this.account = data
            // 自动注册后，显示绑定手机号
            this.showBindMobileDialog()
          })
        }
      }).catch(() => {
        this.fastRegisterLoading = false
      })
    },
    showBindMobileDialog () {
      this.mobileBindDialogVisible = true
    },
    handleBindMobileNext () {
      this.mobileBindDialogVisible = false
      this.checkShowBindIdCardDialog()
    },
    checkShowBindIdCardDialog () {
      // 0 关闭防沉迷, 1/2 开启防沉迷 弹出实名认证
      const juvenileSwitch = this.account.juvenileSwitch || 0
      if (juvenileSwitch !== 0) {
        this.showBindIdCardDialog()
      } else {
        window.location.href = this.account.gameUrl
      }
    },
    showBindIdCardDialog () {
      this.idCardBindDialogVisible = true
    },
    handleBindIdCardNext (data) {
      this.idCardBindDialogVisible = false
      const { playable, tips } = data
      if (!playable) {
        this.$message.error(tips)
        return
      }
      window.location.href = this.account.gameUrl
    },
    checkAutoLogin () {
      if (!this.currentUsername || !this.currentPassword) {
        return
      }
      this.autoLoginShow = true
      this.loginLoading = true
      this.timeSeconds = 3
      this.timerTask()
    },
    timerTask () {
      if (this.timeSeconds > 0) {
        this.timeSeconds--
        this.timer = setTimeout(() => {
          this.timerTask()
        }, 1000)
      } else {
        this.timeSeconds = 0
        this.handleSubmit()
      }
    },
    handleCancelAutoLogin () {
      this.autoLoginShow = false
      this.loginLoading = false
      clearTimeout(this.timer)
    }
  },
  destroyed () {
    clearTimeout(this.timer)
  }
}
</script>
