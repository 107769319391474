import http from './http'
import util from '../common/util'
import qs from 'qs'

const doHttpPostWithSign = (uri, params) => {
  return http.post(uri, qs.stringify(util.encryptData(params)))
}

export default {
  h5Login (params) {
    return doHttpPostWithSign('/h5/user/login', params)
  },
  h5Register (params) {
    return doHttpPostWithSign('/h5/user/register', params)
  },
  h5RegisterFast (params) {
    return doHttpPostWithSign('/h5/user/register/fast', params)
  },
  smsCodeSend (params) {
    return doHttpPostWithSign('/sms/code/send', params)
  },
  passwordReset (params) {
    return doHttpPostWithSign('/h5/user/password/reset', params)
  },
  userBindPhone (params) {
    return doHttpPostWithSign('/h5/user/bind/phone', params)
  },
  userBindIdCard (params) {
    return doHttpPostWithSign('/h5/user/bind/idcard', params)
  }
}
