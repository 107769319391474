<template>
  <div>
    <a-modal v-model="dialogVisible" title="请绑定手机号" :closable="false" :maskClosable="false">
      <template slot="footer">
        <a-row :gutter="4" type="flex" justify="space-around">
          <a-col :span="8">
            <a-button type="primary" block ghost size="large" @click="onCancel">取 消</a-button>
          </a-col>
          <a-col :span="8">
            <a-button type="primary" block size="large" @click="onConfirm" :loading="bindLoading">确 认</a-button>
          </a-col>
        </a-row>
      </template>
      <a-form :form="form">
        <a-form-item>
          <a-input placeholder="手机号" v-decorator="formRules.phone" size="large">
            <a-icon slot="prefix" type="user" class="i-icon"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-row :gutter="4">
            <a-col :span="14">
              <a-input placeholder="验证码" v-decorator="formRules.code" size="large"/>
            </a-col>
            <a-col :span="10">
              <a-button type="primary" ghost block size="large" :disabled="disabled" html-type="button" @click="handleSmsCodeSend()" :loading="smsCodeLoading">{{ btnTxt }}</a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import api from '../api'
export default {
  name: 'XsMobileBind',
  props: {
    visible: {
      type: Boolean,
      default: false,
      immediate: true
    }
  },
  data () {
    return {
      dialogVisible: !!this.visible,
      bindLoading: false,
      smsCodeLoading: false,
      disabled: false,
      btnTxt: '发送验证码',
      timer: null,
      form: this.$form.createForm(this, { name: 'mobile_bind_login' }),
      formRules: {
        phone: [
          'phone',
          {
            rules: [{
              required: true,
              message: '请输入手机号！'
            }]
          }
        ],
        code: [
          'code',
          {
            rules: [{
              required: true,
              message: '请输入验证码！'
            }]
          }
        ]
      }
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    currentAccount () {
      return this.$store.getters.currentAccount
    }
  },
  watch: {
    visible (val, old) {
      this.dialogVisible = val
    }
  },
  methods: {
    onCancel () {
      this.dialogVisible = false
      this.$emit('next', false)
    },
    onConfirm () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const phone = values.phone
        if (phone.length !== 11) {
          this.$message.error('手机号格式错误，请重新输入')
          return
        }
        const params = {
          appId: this.appId,
          phone: phone,
          code: values.code,
          uid: this.currentAccount.uid,
          token: this.currentAccount.token
        }
        this.bindLoading = true
        api.userBindPhone(params).then(res => {
          this.bindLoading = false
          const { code, msg } = res
          if (code !== 1) {
            this.$message.error(msg || '手机号绑定失败')
          } else {
            this.$message.success('手机号绑定成功')
            this.$emit('next', true)
            this.dialogVisible = false
          }
        }).catch(() => {
          this.bindLoading = false
        })
      })
    },
    timerTask () {
      if (this.time > 0) {
        this.disabled = true
        this.time--
        this.btnTxt = this.time + '秒'
        this.timer = setTimeout(this.timerTask, 1000)
      } else {
        this.time = 0
        this.btnTxt = '发送验证码'
        this.disabled = false
      }
    },
    handleSmsCodeSend () {
      this.form.validateFields(['phone'], (err, values) => {
        if (err) {
          return
        }
        const params = {
          appId: this.appId,
          type: 'bind',
          phone: values.phone
        }
        this.smsCodeLoading = true
        // http 请求
        api.smsCodeSend(params).then(res => {
          this.smsCodeLoading = false
          if (res.code !== 200) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('验证码发送成功')
            this.time = 60
            this.timerTask()
          }
        }).catch(() => {
          this.smsCodeLoading = false
        })
      })
    }
  }
}
</script>
