<template>
  <div class="box">
    <div class="box-title">
      <h1>忘记密码</h1>
    </div>
    <a-form :form="form" @click="handleSubmit">
      <a-form-item>
        <a-input placeholder="手机号" v-decorator="formRules.username" size="large">
          <a-icon slot="prefix" type="user" class="i-icon"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-row :gutter="4">
          <a-col :span="14">
            <a-input placeholder="验证码" v-decorator="formRules.code" size="large"/>
          </a-col>
          <a-col :span="10">
            <a-button type="primary" ghost size="large" block :disabled="disabled" html-type="button" @click="handleSmsSend" :loading="smsLoading">{{ btnTxt }}</a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item>
        <a-input-password type="password" placeholder="新密码" v-decorator="formRules.password1" size="large">
          <a-icon slot="prefix" type="lock" class="i-icon"/>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input-password type="password" placeholder="确认密码" v-decorator="formRules.password2" size="large">
          <a-icon slot="prefix" type="lock" class="i-icon"/>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" block size="large" @click="handleSubmit" :loading="btnLoading">确 认</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="link" @click="$router.push({ path: '/login' })">返回登录</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'Forget',
  data () {
    return {
      disabled: false,
      btnTxt: '发送验证码',
      timer: null,
      btnLoading: false,
      smsLoading: false,
      form: this.$form.createForm(this, { name: 'normal_pwd_forget' }),
      formRules: {
        username: [
          'username',
          {
            rules: [{
              required: true,
              message: '请输入手机号！'
            }]
          }
        ],
        code: [
          'code',
          {
            rules: [{
              required: true,
              message: '请输入验证码！'
            }]
          }
        ],
        password1: [
          'password1',
          {
            rules: [{
              required: true,
              message: '请输入密码！'
            }]
          }
        ],
        password2: [
          'password2',
          {
            rules: [{
              required: true,
              message: '请再次输入密码！'
            }]
          }
        ]
      }
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  methods: {
    handleSmsSend () {
      this.form.validateFields(['username'], (err, values) => {
        if (err) {
          return
        }
        const phone = values.username
        if (phone.length !== 11) {
          this.$message.error('手机号格式错误，请重新输入')
          return
        }
        const params = {
          appId: this.appId,
          type: 'changePassword',
          phone: phone
        }
        this.smsLoading = true
        // http 请求
        api.smsCodeSend(params).then(res => {
          this.smsLoading = false
          if (res.code !== 200) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('验证码发送成功')
            this.time = 60
            this.timerTask()
          }
        }).catch(() => {
          this.smsLoading = false
        })
      })
    },
    // 发送手机验证码倒计时
    timerTask () {
      if (this.time > 0) {
        this.disabled = true
        this.time--
        this.btnTxt = this.time + '秒'
        this.timer = setTimeout(this.timerTask, 1000)
      } else {
        this.time = 0
        this.btnTxt = '发送验证码'
        this.disabled = false
      }
    },
    handleSubmit () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        if (values.password1 !== values.password2) {
          this.$message.warning('两次密码不一致')
          return
        }
        const params = {
          appId: this.appId,
          phone: values.username,
          code: values.code,
          password: values.password1
        }
        this.btnLoading = true
        // http 请求
        api.passwordReset(params).then(res => {
          this.btnLoading = false
          const code = res.code
          if (code !== 1) {
            const message = res.msg || '用户名密码错误'
            this.$message.error(message)
          } else {
            this.$message.success('密码重置成功')
          }
        }).catch(() => {
          this.btnLoading = false
        })
      })
    }
  },
  destroyed () {
    clearTimeout(this.timer)
  }
}
</script>
