<template>
 <div class="box">
   <h1>Page Not Found</h1>
   <img src="@/assets/404.png" alt="404">
   <h2>OOPS!</h2>
   <p>Please check that the URL you entered is correct, or click the button below to return to the homepage.</p>
   <a-button type="primary" @click="$router.push({ path: '/login' })">返回登录</a-button>
 </div>
</template>

<script>
export default {
  name: 'E404'
}
</script>
