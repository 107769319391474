<template>
  <div>
    <a-modal v-model="dialogVisible" title="请进行实名认证" :closable="false" :maskClosable="false">
      <template slot="footer">
        <a-row :gutter="4" type="flex" justify="space-around">
          <a-col :span="8">
            <a-button type="primary" block ghost size="large" @click="onCancel">取 消</a-button>
          </a-col>
          <a-col :span="8">
            <a-button type="primary" block size="large" @click="onConfirm" :loading="bindLoading">认 证</a-button>
          </a-col>
        </a-row>
      </template>
      <a-form :form="form">
        <a-form-item>
          <a-input placeholder="姓名" v-decorator="formRules.realName" size="large">
            <a-icon slot="prefix" type="user" class="i-icon"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input placeholder="身份证号" v-decorator="formRules.idCard" size="large">
            <a-icon slot="prefix" type="idcard" class="i-icon"/>
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import api from '../api'
export default {
  name: 'XsIdCardBind',
  props: {
    visible: {
      type: Boolean,
      default: false,
      immediate: true
    }
  },
  data () {
    return {
      dialogVisible: !!this.visible,
      bindLoading: false,
      form: this.$form.createForm(this, { name: 'mobile_bind_login' }),
      formRules: {
        realName: [
          'realName',
          {
            rules: [{
              required: true,
              message: '请输入您的姓名！'
            }]
          }
        ],
        idCard: [
          'idCard',
          {
            rules: [{
              required: true,
              message: '请输入身份证号！'
            }]
          }
        ]
      }
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    currentAccount () {
      return this.$store.getters.currentAccount
    }
  },
  watch: {
    visible (val, old) {
      this.dialogVisible = val
    }
  },
  methods: {
    onCancel () {
      this.dialogVisible = false
      const data = {
        playable: true,
        tips: ''
      }
      this.$emit('next', data)
    },
    onConfirm () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const idCard = values.idCard
        if (idCard.length !== 18 && idCard.length !== 15) {
          this.$message.error('身份证号码错误，请重新输入')
          return
        }
        const params = {
          appId: this.appId,
          realName: values.realName,
          idCard: idCard,
          uid: this.currentAccount.uid,
          token: this.currentAccount.token
        }
        this.bindLoading = true
        api.userBindIdCard(params).then(res => {
          this.bindLoading = false
          const { code, msg, data } = res
          if (code !== 1) {
            this.$message.error(msg || '实名认证失败')
          } else {
            this.$message.success('实名认证成功')
            this.$emit('next', data)
            this.dialogVisible = false
          }
        }).catch(() => {
          this.bindLoading = false
        })
      })
    }
  }
}
</script>
